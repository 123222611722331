<template>
    <verify-user-info-dialog />
    <lazy-dialog-logout-dialog v-if="token" />
    <send-mail-verify-succes-dialog />
    <un-verify-email-dialog />
    <new-promotion-dialog />
</template>
<script setup lang="ts">
import VerifyUserInfoDialog from "~/components/dialog/VerifyUserInfoDialog.vue";
import SendMailVerifySuccesDialog from "~/components/dialog/SendMailVerifySuccesDialog.vue";
import UnVerifyEmailDialog from "~/components/dialog/UnVerifyEmailDialog.vue";
import NewPromotionDialog from "~/components/dialog/NewPromotionDialog.vue";

const token = useCustomCookie("token");
</script>
