<template>
	<v-dialog
		v-model="verifyUserInfoDialog"
		width="auto"
	>
		<v-card class="!px-[32px] !py-[40px] !bg-[#49198E] !rounded-[16px] max-w-[560px] w-[calc(100vw-68px)] mx-[10px]">
			<img
				src="/images/icon/security-user-icon.png"
				alt="security-user-icon"
				class="m-auto my-0 !mb-[24px] w-[102px]"
			>
			<span
				class="uppercase font-[700] text-[#ffffff] text-[24px] leading-[32px] text-center mb-[16px]"
			>
				{{ $t('dialog.verify_user_info.title') }}
			</span>
			<span
				class="max-w-[330px] m-auto font-[400] text-[#F2D6FF] text-[14px] leading-[21px] text-center mb-[32px]"
			>
				{{ $t('dialog.verify_user_info.please_verify_mess') }}
			</span>
			<span class="line-gradient mb-[32px]"></span>
			<NuxtLink :to="ROUTES_NAME.PROFILE" @click="close()" class="uppercase m-auto text-white btn-gradient w-[100%] h-[56px] rounded-[100px]">
				{{ $t('common.message.verify_now') }}
			</NuxtLink>
		</v-card>
	</v-dialog>

</template>
<script setup lang="ts">
import {useSystemStore} from "~/stores/systemStore";
import {storeToRefs} from "pinia";
import { ROUTES_NAME } from '~/config/router';

const useSystem = useSystemStore();
const {verifyUserInfoDialog} = storeToRefs(useSystem);

const close = () => {
	useSystem.changeVerifyUserInfoDialog(false);
}

</script>
<style scoped lang="scss">
	.line-gradient {
		height: 2px;
		width: 100%;
		display: block;
		background: linear-gradient(90deg, rgba(192, 173, 216, 0) 0%, rgba(189, 173, 216, 0.4) 49.48%, rgba(192, 173, 216, 0) 100%);
	}

	.btn-gradient {
		background: linear-gradient(270.46deg, #FF3ECA 0.23%, #9B37FF 40%, #8378FF 55.55%, #53CCFF 98.94%);
		font-size: 15px;
		font-weight: 700;
		line-height: 21px;
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			margin-left: 12px;
			font-size: 21px;
		}

		&:hover {
			opacity: 0.8;
		}
	}

</style>
